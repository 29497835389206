<template>
  <v-container class="py-7">
    <v-form ref="form" lazy-validation>
      <div class="heading text-center dark--text mb-7">
        Регистрация
        <br />нового пользователя
      </div>
      <!-- <v-img :src="$store.getters['app/logo']" height="80" center contain class="mx-auto mb-7" /> -->
      <v-text-field
        outlined
        class="mb-4"
        label="Ваше имя"
        type="text"
        hide-details
        v-model="name"
        :rules="[(v) => !!v]"
      />
      <v-text-field
        outlined
        class="mb-4"
        label="Телефон"
        type="tel"
        hide-details
        v-model="phone"
        :rules="[(v) => !!v]"
        v-mask="phoneMask"
        @input="onPhoneInput"
      />
      <v-text-field
        outlined
        class="mb-4"
        label="Пароль"
        type="password"
        hide-details
        v-model="password"
        :rules="[(v) => !!v]"
      />
      <v-text-field
        outlined
        label="Повторите пароль"
        type="password"
        hide-details
        v-model="passwordRepeat"
        :rules="[(v) => !!v]"
      />
      <v-checkbox
        color="primary"
        value="true"
        v-model="rules"
        required
        :rules="[(v) => !!v || 'Согласитесь с правилами, чтобы продолжить!']"
      >
        <template slot="label">
          Я согласен
          <router-link to="/rules" class="rules-link">с правилами</router-link>
        </template>
      </v-checkbox>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'

const fields = ['name', 'phone', 'password', 'passwordRepeat']

let computed = {}

fields.forEach((name) => {
  computed[name] = {
    get() {
      return this.$store.state.registration[name]
    },
    set(value) {
      const { commit } = this.$store
      commit('registration/onChange', { name, value })
    },
  }
})

export default {
  mixins: [actionButtonMixin],

  data: () => ({
    rules: false,
  }),

  computed: {
    ...computed,

    actionButton() {
      return {
        active: true,
        text: 'Далее',
        action: this.submit,
      }
    },

    phoneMask() {
      return this.$store.getters['app/phoneMask']
    },

    ...mapGetters('app', ['phoneMask', 'phonePrefix']),
  },

  methods: {
    async submit() {
      if (!this.validate()) return

      const response = await this.registration({
        first_name: this.name,
        contact_phone: this.phone,
        email_phone: this.phone,
        password: this.password,
        cpassword: this.passwordRepeat,
      })

      if (response.code === 1) {
        this.$store.commit('registration/resetRegistration')
      }
    },

    validate() {
      return this.$refs.form.validate()
    },

    ...mapActions('user', ['registration']),

    onPhoneInput(value) {
      const { phoneMask, phonePrefix } = this
      const { VMask } = this.$options.filters

      const phoneMaskLength = phoneMask?.toString().replace(/[^#]/g, '').length
      const phonePrefixLength = phonePrefix
        ?.toString()
        .replace(/\D+/g, '').length

      let number = value.replace(/\D+/g, '')

      if (
        number.length > phoneMaskLength + phonePrefixLength &&
        (number[phonePrefixLength] === '7' || number[phonePrefixLength] === '8')
      ) {
        number = number.substring(1 + phonePrefixLength)
        this.$nextTick(() => {
          this.phone = VMask(number, phoneMask)
        })
      }
    },
  },
}
</script>
